
.block--text {
    &.bg-grey {
        background-color: #ededed; }

    .special-link {
        transition: 0.5s;
        color: $body-link-color;
        &:hover {
            color: $body-link-hover-color;
            transition: 0.5s; } }

    .text-icon {
        display: inline-flex;
        width: 18px;
        margin: auto 0 auto 5px; }

    a {
        text-decoration: none; }

    .btn-wrap {
        &.text-center {
            display: flex;
            justify-content: center; } } }
