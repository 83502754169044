// base
$base-width: .8rem;

// font
$font-headline: 'Changa One';
$font-text: 'Open Sans Regular';
$font-strong: 'Open Sans Regular';
$font-nav: 'Open Sans Light';

// general colors
$primary: #008A81;
$secondary: #008A81;
$tertiary: #008A81;
$dark: #000;
$light: #e6e6e6;
$success: #0aa524;
$warning: #e6cf00;
$danger: #9b0000;
$grey: #999;

$bg-grey: #f6f6f6;

// nav
$nav-link-color: white;
$nav-link-hover-color: white;

$nav-sub-link-color: white;
$nav-sub-link-hover-color: $primary;


// head
$head-text-color: #333;
$head-link-color: #333;
$head-link-hover-color: #777;

// body
$body-text-color: #333;
$body-link-color: #333;
$body-link-hover-color: #777;

// footer
$footer-header-color: #fff;
$footer-text-color: #fff;
$footer-link-color: #fff;
$footer-link-hover-color: #fff;


//Icons
.filter-white {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    transition: 0.5s; }

.filter-black {
    filter: invert(0%) sepia(6%) saturate(7499%) hue-rotate(170deg) brightness(90%) contrast(99%);
    transition: 0.5s; }

.filter-primary {
    filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
    transition: 0.5s; }


// Transition
$transition: all .5s ease-in-out;

// Widths
$max: 2560px;
$boxed: 1540px;
$small: 1016px;

// Transitions
$transition: .3s;


// Breakpoints
$breakpoints: ("xs": 480px, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1480px, "xxxl": 1680px);
$grid-breakpoints: ("xs": 0, "sm": 600px, "md": 768px, "lg": 992px, "xl": 1350px, "xxl": 1500px, "xxxl": 1800px);
