.block--leistungs-icons {
  text-align: center;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    margin-top: 50px;
    margin-bottom: 50px; }

  .leistungs-icons-inner {
    padding: 135px 60px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 50px 60px; }

    .row {
      justify-content: center;
      flex-wrap: nowrap;
      @media (max-width: #{map-get($grid-breakpoints, xxxl)}) {
        flex-wrap: wrap; } } }

  h2 {
    margin-bottom: 90px; }


  a {
    text-decoration: none;
    width: 300px;
    margin: 0  25px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      width: 50%; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      width: 100%; }
    @media (max-width: #{map-get($grid-breakpoints, xxxl)}) {
      margin: 15px 25px; }

    &:hover {
      text-decoration: none; } }

  .leistung-container {
    width: 100%;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      margin-bottom: 50px; }

    .leistung-icon-container {
      display: flex;
      border: 2px solid $primary;
      border-radius: 50%;
      width: 118px;
      height: 118px;
      margin: auto;
      transition: 0.5s;
      img {
        margin: auto;
        transition: 0.5s;
        padding: 25px; } }

    .leistung-text-container {
      p {
        color: black;
        line-height: 27px;
        margin-top: 20px;
        transition: 0.5s;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          font-size: 14px; } } } }


  .leistung-container {
    &:hover {

      .leistung-icon-container {
        background-color: $primary;
        transition: 0.5s;
        img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          transition: 0.5s; } }

      .leistung-text-container {
        p {
          color: $primary;
          transition: 0.5s; } } } } }
