#BorlabsCookieBox {
  .cookie-logo {
    height: auto;
    max-width: 360px!important;
    width: 100%;
    padding: 0 8px 0 0;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 25px; }

  ._brlbs-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column; } }


.BorlabsCookie {
  h3 {
    color: #333;
    text-transform: none;
    margin-top: 30px; } }


.embed-responsive {
  border: 3px solid #008A81!important;
  .BorlabsCookie {
    position: absolute;
    top: 0; }
  ._brlbs-caption {
      background-color: rgba(255, 255, 255, 0.85)!important;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
