.block--text-mit-medien {

  .row {
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      margin-top: 0px;
      margin-bottom: 0px; } }

  .text_mit_bild_links {
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      flex-direction: column-reverse; }
    .bild-container {
      overflow: hidden;
      padding-left: 0;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex-direction: column; }
      img {
        transition: 0.5s;
        padding-bottom: 2px;

        &:hover {
          transform: scale(1.1);
          transition: 0.5s; } } }
    .text-container {
      padding: 0 0 0 135px;
      @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
        padding-top: 25px; }
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 50px 0 50px 135px; }
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 50px 40px 20px 40px; }
      img {
        transition: 0.5s;
        &:hover {
          transform: rotate(5deg);
          transition: 0.5s; } }
      h3 {
        margin-bottom: 10px; }

      .row {
        padding-right: 30px;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          padding-right: 0px; } } } }

  .text_mit_bild_rechts {
    flex-direction: row-reverse;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      flex-direction: column-reverse; }
    .bild-container {
      display: flex;
      overflow: hidden;
      padding-right: 0;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex-direction: column; }
      img {
        transition: 0.5s;
        padding-bottom: 2px;

        &:hover {
          transform: scale(1.1);
          transition: 0.5s; } } }

    .text-container {
      padding: 0 135px 0 0;
      @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
        padding-top: 25px; }
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 50px 135px 50px 0; }
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 50px 40px 20px 40px; }
      img {
        transition: 0.5s;
        &:hover {
          transform: rotate(5deg);
          transition: 0.5s; } }
      h3 {
        margin-bottom: 10px; }

      .row {
        padding-right: 30px;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          padding-right: 0px; } } } }

  .zusatz-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    padding: 0;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      padding: 0 10px; }

    a {
      &:hover {
        transform: rotate(0deg) !important;
        .button-link-icon {
          transform: rotate(0deg) !important;
          margin-left: 40px;
          margin-right: -20px;
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          transition: 0.5s; } }

      .button-link-icon {
        height: 18px;
        display: inline-flex;
        margin-left: 20px;
        filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
        transition: 0.5s; } } }

  .text_mit_grafik_rechts {

    .text-mit-grafik-logo-row {
      margin-top: 50px;
      margin-bottom: 50px;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        margin-top: 25px;
        margin-bottom: 25px; }
      .text-mit-grafik-logo-col {
        display: flex;
        img {
          height: 60px;
          width: auto;
          margin-right: 20px; } } }

    .text-container {
      padding: 0 25px 0 0;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 20px 25px 20px 0; }
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 20px 40px 20px 40px; }
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding: 20px 10px 20px 10px; }
      h2 {
        margin-top: 135px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          margin-top: 50px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          margin-top: 25px; } }
      h3 {
        margin-bottom: 10px; } }

    .bild-container {
      .grafik-wrapper {
        @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
          flex-direction: column; }
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          flex-direction: row; }

        .grafik-container {
          padding: 0px;
          img {
            padding: 0px; } }
        .grafik-button-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: -80px;
          @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
            margin-left: 0px; }
          @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            margin-left: -20px; }
          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            margin-left: 0px; }

          .zusatz-container {
            padding: 0;
            margin-top: 10px; } } } } }

  .text_mit_grafik_links {
    flex-direction: row-reverse;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      flex-direction: column-reverse; }

    .text-mit-grafik-logo-row {
      margin-top: 50px;
      margin-bottom: 50px;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        margin-top: 25px;
        margin-bottom: 25px; }
      .text-mit-grafik-logo-col {
        display: flex;
        img {
          height: 50px;
          width: auto;
          margin-right: 30px; } }

      .zusatz-container {
        padding: 0;
        margin-top: 10px; } }

    .text-container {
      padding: 0 0 0 25px;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 20px 0 0 25px; }
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 20px 40px 0 40px; }
      h2 {
        margin-top: 135px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          margin-top: 50px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          margin-top: 25px; } }

      h3 {
        margin-bottom: 10px; } }

    .bild-container {
      .grafik-wrapper {
        @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
          flex-direction: column; }
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          flex-direction: row; }

        .grafik-container {
          padding: 0px;
          img {
            padding: 0px; } }
        .grafik-button-container {
          display: flex;
          flex-direction: column;
          justify-content: end;
          margin-left: -80px;
          @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
            margin-left: 0px; }
          @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            margin-left: -20px; }
          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            margin-left: 0px; } } } } }

  .split_5050 {
    .text-container {
      padding: 135px 120px 135px 120px;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 20px 50px 0 50px; }
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-top: 30px; }


      .zusatz-container {
        .row {
          margin: 0; }
        .buttons-row {
          display: flex;
          justify-content: start;
          flex-direction: column;
          align-items: start;
          p {
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            line-height: 25px;
            a {
              text-decoration: none;
              color: #008A81;
              &:hover {
                color: #000; } } } } } }

    .bild-container {
      display: flex;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transform: scale(1.005);
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          object-fit: contain; } } }

    h3 {
      margin-bottom: 10px;



      h3 {
        margin-top: 35px; } }

    .bild-container {
      overflow: hidden;
      position: relative;
      display: flex;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        height: 550px; }
      img {
        transition: 0.5s;
        object-fit: cover;
        width: 100%;
        height: auto;

        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          margin: auto;
          height: 100%;
          width: 100%; } }
      &:hover {
        img {
          transform: scale(1.1);
          transition: 0.5s; } } }
    .zoom-symbol {
      width: 60px;
      height: 60px;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: flex;
      .zoom-symbol-cross {
        width: 20px;
        height: 20px;
        margin: auto!important;
        &:hover {
          transform: scale(1)!important; } } } } }

.background-frame {
  .text_mit_bild_links {
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      flex-flow: row!important; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      flex-flow: column-reverse!important; } }
  .bild-container {
    display: flex;
    img {
      width: 100%;
      height: auto;
      object-fit: cover; }
    .zoom-symbol {
      background-color: rgba(0, 0, 0, 0.55);
      padding: 20px;
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: 2%;
      right: 2%; } } }


.background-frame {
  background-image: url('/wp-content/uploads/concrete_wall_2.jpg');
  padding-top: 150px;
  padding-bottom: 150px;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    padding-top: 50px;
    padding-bottom: 50px; }
  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    padding-top: 25px;
    padding-bottom: 25px; } }


.__bg-color {
  background-color: rgba(207, 207, 207, 0.5); }

.__bg-image {
  background-image: url('/wp-content/uploads/concrete_wall_2.jpg');
  background-repeat: repeat; }
