.block--formular {

  input.invalid {
    background-color: #ffdddd; }

  form input {
    width: 100%;
    padding: 5px 12px;
    font-size: 19px;
    margin-bottom: 25px; }

  form textarea {
    height: 150px;
    font-size: 19px; }

  input[type="checkbox"] {
    width: auto;
    margin-bottom: 5px; }

  label {
    font-size: 19px;
    color: grey;
    width: 100%; }

  .form-check {
    padding-left: 2px;
    font-size: 19px; }

  .form-control {
    font-size: 19px;

    &.confirm {
      transform: translateX(-9999px);
      position: absolute; } }

  #dsvg-container {
    margin-top: 25px;
    .form-check-label {
      padding-left: 25px;
      font-size: 16px; }
    .form-check-input {
      margin-buttom: 0; } }

  .form-error {
    border: 2px solid rgba(208, 0, 0, 0.8);
    padding: 25px;
    background-color: rgba(208, 0, 0, 0.25);
    p {
      font-size: 20px; } }

  .form-success {
    border: 2px solid rgba(46, 213, 138, 0.8);
    padding: 25px;
    background-color: rgba(46, 213, 138, 0.25);
    p {
      font-size: 20px; } }

  .error {
    border: 2px solid rgba(208, 0, 0, 0.8);
    background-color: rgba(208, 0, 0, 0.25); }

  .form-text-container {
    background-color: $secondary;
    max-height: 625px;
    // padding: 50px
    padding: 0;
    h2,h3,p, a {
      color: white; }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline; } }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  .form-formular-container {
    padding: 50px 60px 50px 120px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 50px 30px 50px 60px; } }


  .form-additional-text {
    color: #777;
    font-size: 13px;
    padding: 0;
    margin: 12px 0 0 0;
    line-height: 1.6; } }
