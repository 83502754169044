.timeline {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
    //background-color: $light
    background-image: url('/wp-content/uploads/concrete_wall_2.jpg');
    padding: 5% 0;
    &::after {
        content: "";
        position: absolute;
        width: 6px;
        background-color: white;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
        box-sizing: border-box; } }

.container-timeline {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    box-sizing: border-box;
    &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        right: -15px;
        background-color: white;
        border: 4px solid $primary;
        top: 50px;
        border-radius: 50%;
        z-index: 1; } }

.timeline-left {
    left: 0; }

.timeline-right {
    left: 50%; }

.timeline-left {
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 55px;
        width: 0;
        z-index: 1;
        right: 30px;
        border: medium solid $primary;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent $primary; } }

.timeline-right {
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 55px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent $primary transparent transparent; }
    &::after {
        left: -16px; } }

.content-timeline {
    padding: 10px 15px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 0 10%; }

/*Fonts*/

.content-timeline {
    h3 {
        font-size: 2em;
        color: $tertiary;
        margin: 25px 10px 10px 10px; }
    p {
        margin: 0px 2%; } }

@media (max-width: 600px) {

    .timeline-right {
        left: 0%; }

    .timeline::after {
        left: 31px; }

    .container-timeline {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
        box-sizing: border-box;
        &::before {
            left: 60px;
            border: medium solid white;
            border-width: 10px 10px 10px 0;
            border-color: transparent white transparent transparent;
            box-sizing: border-box; } }

    .timeline-left {
        &::after {
            left: 15px; } }

    .timeline-right {
        &::after {
            left: 15px; } } }

.timeline {
    .timeline-gallery-row {
        margin: 0;
        padding: 5px;
        .gallery-single-image-container {
            height: 300px;
            padding: 5px;
            margin: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain; } }
        .gallery-container {
            height: 150px;
            padding: 5px;
            margin: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $transition;
                border: 5px solid transparent;
                &:hover {
                    border: 5px solid $primary;
                    transition: $transition; } } } } }
