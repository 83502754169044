ul {
    list-style-type: none;
    li {
        font-size: 20px;
        line-height: 1.6;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 18px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            font-size: 16px; } } }

.listen-block {
    margin-top: 50px;
    margin-bottom: 50px;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            hyphens: none; }
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            hyphens: auto; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            flex-direction: column;
            text-align: center;
            align-items: center;
            hyphens: none; } }


    .text-icon {
        width: 25px;
        margin-right: 30px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            margin-right: 0;
            margin-bottom: 25px; } }



    .text-item {
        width: 80%;
        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
            width: 100%; } }

    a {
        text-decoration: none;
        color: $primary;
        &:hover {
            text-decoration: underline;
            color: $primary; } } }

.block--text-in-2-spalten,
.block--text-mit-nummern,
.block--accordion,
.block--text,
.block--text-mit-medien {
    ul {
        list-style-position: outside;
        list-style: disc;
        margin-left: 20px; }

    ol {
        list-style-position: outside;
        list-style: decimal;
        margin-left: 20px; }

    li {
        font-size: 20px;
        line-height: 30px;
        color: $body-text-color;
        font-family: $font-text;
        margin-bottom: 15px;
        &::marker {
            color: $primary; }
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 18px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            font-size: 16px; } } }
