.header-icon {
    display: inline-block;
    width: 14px!important;
    height: auto;
    margin: 0 5px -2px 0; }

#StartseiteHero {
    margin-top: 105px;

    .carousel-inner {
        height: 75vh;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: 500px; }

        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
            height: 415px; }

        .carousel-item {
            position: relative;
            height: 100%;

            .hero-picture, .bg-video {
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: bottom; }

            .hero-icon-container {
                position: absolute;
                bottom: 60px;
                left: 120px;
                z-index: 10;
                display: flex;
                @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                    left: 60px; }
                @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                    flex-direction: column;
                    left: 0px;
                    right: 0px; }
                a {
                    display: flex;
                    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                        flex-direction: column; }
                    &:hover {
                        cursor: pointer; }

                    .hero-icon {
                        margin: auto;
                        width: 70px;
                        height: 70px;
                        border: 2px solid white;
                        border-radius: 50%;
                        display: flex;
                        transition: 0.5s;
                        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
                            width: 60px;
                            height: 60px; }

                        img {
                            width: 20px;
                            height: auto;
                            transition: 0.1s;
                            margin: auto auto auto 25px;

                            @media (max-width: #{map-get($grid-breakpoints, sm)}) {
                                width: 12px;
                                height: auto;
                                transition: 0.1s;
                                margin: auto auto auto 23px; } }

                        &:hover {
                            background-color: white;
                            transition: 0.5s;
                            img {
                                transition: 0.1s;
                                filter: invert(75%) sepia(150%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%); } } }

                    .hero-icon-text {
                        font-family: $font-nav;
                        line-height: 35px;
                        font-size: 25px;
                        color: white;
                        margin: auto auto auto 30px;
                        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                            margin: auto;
                            text-align: center;
                            margin-top: 25px; }

                        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
                            margin-top: 15px;
                            line-height: 30px;
                            font-size: 20px; } } } } }

        h1 {
            font-family: $font-headline;
            top: 140px;
            left: 120px;
            font-size: 70px;
            line-height: 75px;
            display: inline-block;
            color: white;
            position: absolute;
            z-index: 9;
            width: 59%;
            text-transform: uppercase;
            @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                width: 80%;
                font-size: 60px;
                line-height: 75px;
                left: 60px; }
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                width: 100%;
                top: 10%;
                font-size: 50px;
                left: 0px;
                text-align: center;
                padding: 0 15px; }
            @media (max-width: #{map-get($grid-breakpoints, md)}) {
                font-size: 30px;
                line-height: 35px; }

            @media (max-width: #{map-get($grid-breakpoints, sm)}) {
                font-size: 26px;
                line-height: 29px; } } } }

#headerFrame {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    pointer-events: none;
    height: 75px;
    bottom: 0; }

#header-titel {
    font-size: 3.5rem!important;
    line-height: 1.6;
    font-weight: bold;
    text-align: left;
    color: $head-text-color;
    border: solid 1px $head-text-color;
    background-color: rgba(255,255,255,0.75);
    padding: 4%;
    display: block; }

#header-text {
    font-size: 2.0rem;
    display: block; }

.overlay-startseite-1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 138, 129, 0.25); }

.overlay-startseite-2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.75) 100%); }

#subpage-container {
    width: 100%;
    height: 105px;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        height: 20px; } }

#meta-menu-add {
    background-color: white;
    padding-left: 20px;
    padding-right: 70px;
    border-bottom-left-radius: 65px;
    box-shadow: 0px 0px 10px 1px rgba(51, 51, 51, 0.5);
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-left: 50px;
        padding-right: 50px; }

    #meta-menu-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2rem;
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 25px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            display: block; }
        .meta-menu-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 0 0 33%;
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                justify-content: left; }
            @media (max-width: #{map-get($grid-breakpoints, md)}) {
                margin-bottom: 10px; }

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                .meta-menu-item-icon {
                    margin-right: 10px;
                    img {
                        width: auto;
                        height: 22px;
                        transition: 0.5s; } }
                .meta-menu-item-nav-item {
                    p {
                        font-family: $font-text;
                        font-size: 14px;
                        margin: 0;
                        transition: 0.5s;
                        padding: 0; } }
                &:hover {
                    img {
                        filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
                        transition: 0.5s; }
                    p {
                        color: $primary;
                        transition: 0.5s; } } } } }

    #meta-menu-divider {
        background-image: linear-gradient(to right, rgba(112, 112, 112, 0.44) 30%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 15px 1px;
        background-repeat: repeat-x;
        height: 1px;
        margin-left: 5px;
        margin-right: 5px; }

    #meta-menu-kontakt-container {
        display: flex;
        padding-top: 15px;
        padding-bottom: 40px;
        justify-content: space-around;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            flex-direction: column; }

        .meta-menu-kontakt-item {
            align-items: center;
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                .meta-menu-kontakt-icon {
                    margin-right: 20px;
                    img {
                        width: auto;
                        height: 20px;
                        transition: 0.5s; } }
                .meta-menu-kontakt-nav-item {
                    p {
                        font-family: $font-text;
                        font-size: 14px;
                        margin: 0;
                        transition: 0.5s;
                        margin-left: -10px;
                        padding: 0; } }
                &:hover {
                    img {
                        filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
                        transition: 0.5s; }
                    p {
                        color: $primary;
                        transition: 0.5s; } } } } } }

.meta-menu-disspear {
    display: none; }

// extras 08.23
.logo-extra-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    .seperator {
        height: 45px;
        width: 1px;
        background-color: $primary; }

    .navbar-brand {
        margin-right: 0; }

    .header-extras-one {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;

        @media (max-width: #{map-get($grid-breakpoints, xxxl)}) {
            display: none; }

        .meta-menu-item {
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                gap: 1rem;

                &:hover {
                    img {
                        filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
                        transition: 0.5s; }

                    p {
                        color: $primary;
                        transition: 0.5s; } }

                p {
                    font-family: $font-text;
                    font-size: 14px;
                    margin: 0;
                    transition: 0.5s;
                    margin-left: -10px;
                    padding: 0; }

                .meta-menu-item-icon {
                    margin-right: 10px;

                    img {
                        width: auto;
                        height: 22px;
                        transition: 0.5s; } } } } } }

.header-extras-two {
    display: flex;
    flex-direction: row;
    gap: 5rem;

    @media (max-width: #{map-get($grid-breakpoints, xxxl)}) {
        display: none; }

    .meta-menu-kontakt-item {
        a {
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover {
                img {
                    filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
                    transition: 0.5s; }

                p {
                    color: $primary;
                    transition: 0.5s; } } }

        p {
            font-family: $font-text;
            font-size: 14px;
            margin: 0;
            transition: 0.5s;
            margin-left: -10px;
            padding: 0; }

        .meta-menu-kontakt-icon {
            margin-right: 20px;

            img {
                width: auto;
                height: 20px;
                transition: 0.5s; } } } }
