.text-mit-nummer-titel {
    color: $primary;
    font-size: 16px;
    text-transform: uppercase; }

.text-mit-nummer-text p {
    color: $body-text-color;
    font-size: 16px; }

.text-mit-nummer-zahl p {
    color: $body-text-color;
    font-size: 50px;
    text-align: center;
    font-weight: bold; }

.border-right {
    border-right: 2px solid $primary;
    margin-right: 15px;
    margin-left: -15px; }
.border-left {
    border-left: 2px solid $primary;
    margin-left: 15px;
    margin-right: -15px; }

