.row.video-row {
	justify-content: center;
	margin-bottom: 5rem; }

.videobox {
	margin-bottom: 70px;
	@media (max-width: #{map-get($grid-breakpoints, lg)}) {
		margin-bottom: 25px; }
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none; } }
	&:hover {
		.title-container {
			margin-left: 0%;
			transition: 0.5s;
			margin-left: 8%; } } }


.video-col {
	@media (max-width: #{map-get($grid-breakpoints, lg)}) {
		margin: 6rem 0; }

	@media (max-width: #{map-get($grid-breakpoints, sm)}) {
		margin: 2rem 0; } }


.video-wrap {
	position: relative;
	cursor: pointer; }

.video-overlay {
		position: absolute;
		opacity: 1;
		transition: 0.5s;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;

		&.one {
			background-color: rgba(0, 138, 129, 0.77); }

		&.two {
			background-image: linear-gradient(rgba(0, 0, 0, 0) 45%, black 100%); } }

.playbutton {
	margin: auto;
	width: 70px;
	height: 70px;
	border: 2px solid white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	transition: 0.5s;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);



	img {
		width: 20px;
		height: auto;
		transition: 0.1s;
		margin-left: 0.5rem; } }




.video-text-container {
	width: 100%;
	transition: 0.5s;
	// min-height: 110px

	h2 {
		text-align: left;
		font-family: $font-headline;
		color: $primary;
		font-size: 2.8rem;
		font-weight: normal;
		line-height: 1.6;
		margin-bottom: 15px;
		text-transform: capitalize;

		&:after {
			display: none; } } }

.block--leistungsboxen-drei-spalten {
	margin-bottom: 50px; }


.block--leistungs-icons +	.block--videoboxen,
.block--text-mit-medien + .block--videoboxen {
	padding-top: 135px;

	@media (max-width: #{map-get($grid-breakpoints, lg)}) {
		padding-top: 80px; } }
