.element-list-pages {
    margin-bottom: 6 * $base-width;

    .grid {
        overflow: visible;

        .col {
            position: relative;
            padding-bottom: 4 * $base-width;

            &__box {
                height: 100%; } }

        a {
            color: $primary;
            display: block;
            padding: 3 * $base-width;
            height: 100%;
            text-decoration: none;

            &:hover {
                background: rgba($grey, .1);

                strong {
                    color: $success; }

                em {
                    span {
                        margin-left: 2 * $base-width; } } }

            strong {
                display: block;
                font-family: $font-text;
                font-size: 3rem;
                padding: 0 0 3 * $base-width;
                transition: $transition;
                text-transform: uppercase;
                font-weight: 300;

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                    font-size: 4rem; } }

            .img {
                display: block;
                width: 100%;
                height: 220px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 0 0 4 * $base-width;
                border-bottom-left-radius: 40px;

                @media only screen and (min-width: #{map-get($breakpoints, l)}) {
                    height: 300px; }

                &.fallback {
                    border: 1px solid $primary; } }

            .icon {
                color: $green;
                transition: $transition;
                font-weight: 700;
                font-style: normal;
                font-size: 2rem;

                span {
                    width: 17px;
                    height: 17px;
                    background: $green;
                    border-radius: 50%;
                    color: $dark;
                    text-align: center;
                    display: inline-block;
                    vertical-align: middle;
                    margin: -3px 0 0 $base-width;
                    transition: $transition;

                    i {
                        line-height: 100%;
                        vertical-align: top;
                        font-size: 1.7rem;
                        padding: 0 0 0 2px;
                        margin-top: -1px;
                        margin-left: -1px; } } } } } }

