main {
     .wp-block-columns {
         display: flex;
         margin-bottom: 28px;
         flex-wrap: wrap; }

     @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
         .wp-block-columns {
             flex-wrap: nowrap;
             max-width: 1920px;
             margin: 0 auto; }

         .wp-block-column:not(:first-child) {
             margin-left: 8 * $base-width; } }

     .wp-block-column {
         flex-grow: 1;
         min-width: 0;
         word-break: break-word;
         overflow-wrap: break-word;

         &.col-33_33 {
             flex-basis: 33.33%; }

         &.col-340 {
             flex-basis: 40%; }

         &.col-50 {
             flex-basis: 50%; }

         &.col-60 {
             flex-basis: 60% !important; }

         &.col-66_66 {
             flex-basis: 100% !important;

             @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                 flex-basis: 66.66%; } } }


     @media only screen and (max-width: 991px) {
         .wp-block-column {
             flex-basis: 100% !important;
             margin-bottom: 4 * $base-width; } }

     @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
         .wp-block-column {
             flex-basis: calc(50% - #{3 * $base-width});
             flex-grow: 0;
             margin-right: $base-width;

             &:nth-child(2n) {
                 margin-left: $base-width;
                 margin-right: 0; } } } }

