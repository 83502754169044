.block--akkordeon {
    h2 {
        margin-top: 135px;
        margin-bottom: 70px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            margin-top: 70px;
            margin-bottom: 35px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            margin-top: 35px;
            margin-bottom: 15px; } }

    .accordion {
        a {
            text-decoration: none;
            color: $body-link-color;
            &:hover {
                text-decoration: none;
                color: $body-link-hover-color; } } }

    .accordion ul {
        font-size: 18px;
        line-height: 1.6;
        list-style-position: outside;
        list-style: circle;
        margin-left: 20px; }

    .accordion ol {
        font-size: 18px;
        line-height: 1.6;
        list-style-position: outside;
        list-style: decimal;
        margin-left: 20px; }


    .card {
        border: none;
        margin-bottom: 15px;
        .card-header {
            padding: 0;
            background: linear-gradient(0deg, rgba(0,69,65,1) 10%, rgba(0,134,126,1) 70%);
            transition: 0.5s;
            display: flex;
            h4 {
                padding-top: 25px;
                padding-bottom: 28px;
                padding-left: 30px; }
            &:hover {
                background: linear-gradient(0deg, #000000 10%, #000000 70%);
                transition: 0.5s; }

            .akkordeon-arrow-icon {
                display: flex;
                margin: auto 30px auto auto;
                .akk-icon-image {
                    width: 20px;
                    height: 20px; } } }



        .card-body {
            padding: 0 25px;
            .akk-bild-conainer {
                max-height: 555px;
                overflow: hidden;
                margin-bottom: 50px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transition: 0.5s;
                    &:hover {
                        transition: 0.5s;
                        transform: scale(1.1); } } }
            h3 {
                margin-top: 50px;
                margin-bottom: 40px; } }


        .akk-active {
            background: linear-gradient(0deg, #000000 10%, #000000 70%); } } }
