a {
    color: $primary;
    text-decoration: underline;
    transition: $transition;

    &:hover {
        color: $body-link-hover-color; }

    &:focus {
        outline: 0; }

    &.icon {
        font-family: $font-text;
        text-decoration: none;

        &:hover {
            span {
                background: $primary; } }

        span {
            width: 17px;
            height: 17px;
            background: $success;
            border-radius: 50%;
            color: $dark;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            margin: -3px 0 0 $base-width;
            transition: $transition;

            i {
                line-height: 100%;
                vertical-align: top;
                font-size: 1.7rem;
                padding: 0 0 0 2px;
                margin-top: -1px;
                margin-left: -1px; } } } }

strong,
.bold {
    font-weight: bold;
    font-family: $font-strong; }

p {
    padding-bottom: 1.2rem;
    font-family: $font-text;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px;
    color: $body-text-color;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 16px; } }

button {
    &:focus {
        outline: none; } }


.text {
    ul {
        list-style: disc;
        padding-left: 3 * $base-width;
        color: $body-text-color;

        li {
            color: $body-text-color;

            &::marker {
                color: $primary; } } }

    ul + p {
        padding-top: 3 * $base-width; } }


.element-headline {
    letter-spacing: normal;
    margin-bottom: 4 * $base-width;

    @media only screen and (min-width: #{map-get($breakpoints, l)}) {
        margin-bottom: 6 * $base-width; }

    &.center {
        text-align: center;

        h1,
        h2,
        h3,
        h4 {
            &:after {
                margin-left: auto;
                margin-right: auto; } } }

    strong {
        font-size: 2.5rem;
        font-family: $font-strong;
        display: block;

        @media only screen and (min-width: #{map-get($breakpoints, l)}) {
            font-size: 3.5rem; } } }

.element-paragraph {
    margin-bottom: 5 * $base-width;

    &.boxed {
        max-width: 960px;
        margin: 0 auto; }

    &.center {
        text-align: center; } }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-bottom: 30px;
    text-transform: uppercase;

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        margin-bottom: 15px; } }

h1,
.h1 {
    font-family: $font-headline;
    font-size: 60px;
    line-height: 68px;
    display: block;
    color: $primary;

    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        font-size: 50px;
        line-height: 55px; }

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 35px;
        line-height: 40px;
        hyphens: auto; } }


h2,
.h2 {
    font-family: $font-headline;
    font-size: 45px;
    line-height: 50px;
    display: block;
    color: $secondary;

    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        font-size: 40px;
        line-height: 45px;
        hyphens: auto; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 38px;
        line-height: 35px;
        hyphens: auto; }

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        font-size: 24px;
        line-height: 30px;
        hyphens: auto; } }


h3,
.h3 {
    font-family: $font-headline;
    font-size: 30px;
    line-height: 35px;
    display: block;
    color: $tertiary;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        hyphens: auto; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 22px;
        line-height: 30px; } }


h4,
.h4 {
    font-family: $font-headline;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 0;
    display: block;
    color: white;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 18px;
        line-height: 20px; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 16px;
        line-height: 16px; }

    &.center {
        text-align: center; } }
