.btn {
	padding: 10px 40px;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	text-transform: none!important;
	transition: 0.5s ease-out;
	border-radius: 25px;
	font-family: $font-text;
	font-weight: 600;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: fit-content;
	justify-content: center;
	align-items: center;
	@media (max-width: #{map-get($grid-breakpoints, xxl)}) {
		font-size: 14px;
		padding: 10px 35px; }
	@media (max-width: #{map-get($grid-breakpoints, md)}) {
		font-size: 12px;
		padding: 10px 30px; } }

.btn-primary {
	border: 2px solid white!important;
	color: white;
	background-color: transparent!important;
	&:active,
	&:focus {
		color: white!important; }
	&:hover {
		color: $primary!important;
		background-color: white!important;
		border: 1px solid white;
		a {
			color: $primary!important; } } }

.btn-secondary {
	border: 2px solid $primary!important;
	color: $primary;
	background-color: transparent!important;
	&:active,
	&:focus {
		color: $primary!important; }
	&:hover {
		color: white!important;
		background-color: $primary!important;
		border: 1px solid $primary;
		a {
			color: white!important; } } }
