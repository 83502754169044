* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 1920px;
    margin: auto; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.6rem;
    color: $primary;
    line-height: 160%;
    font-weight: 300;
    font-family: $font-text;
    overflow-x: hidden;
    margin: auto;
    max-width: 1920px;
    margin: auto;
    box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.13);

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        font-size: 2.3rem; }

    &.customize-support {
        .fixed-top {
            margin-top: 32px!important; }
        #wpadminbar {
            z-index: 99999999999; } } }



.max-width-1920 {
    max-width: 1920px; }

.max-width-1050 {
    max-width: 1050px; }

.hr-grey {
    border-top: 1.5px solid rgba(0, 0, 0, 0.25)!important; }

.padding-links-global {
    padding-left: 120px; }
.padding-rechts-global {
    padding-right: 120px; }
.padding-links-global-half {
    padding: 60px; }
.padding-rechts-global-half {
    padding: 60px; }
.padding-global {
    padding: 0 120px; }

@media (max-width: #{map-get($grid-breakpoints, xl)}) {
    .padding-links-global {
        padding-left: 50px;
        padding-right: 50px; }
    .padding-rechts-global {
        padding-left: 50px;
        padding-right: 50px; }
    .padding-global {
        padding: 0 50px; }
    .padding-links-global-half {
        padding-left: 25px;
        padding-right: 25px; }
    .padding-rechts-global-half {
        padding-left: 25px;
        padding-right: 25px; } }

@media (max-width: #{map-get($grid-breakpoints, md)}) {
    .padding-links-global {
        padding-left: 20px;
        padding-right: 20px; }
    .padding-rechts-global {
        padding-left: 20px;
        padding-right: 20px; }
    .padding-global {
        padding: 0 40px; }
    .padding-links-global-half {
        padding-left: 40px;
        padding-right: 40px; }
    .padding-rechts-global-half {
        padding-left: 40px;
        padding-right: 40px; } }

.space_top_150px {
    padding-top: 150px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-top: 100px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-top: 20px; } }


.space_top_100px {
    padding-top: 100px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-top: 110px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-top: 100px; } }

.space_top_50px {
    padding-top: 50px;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-top: 20px; } }

.space_bottom_150px {
    padding-bottom: 150px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-bottom: 50px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-bottom: 20px; } }

.space_bottom_100px {
    padding-bottom: 100px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding-bottom: 50px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-bottom: 20px; } }

.space_bottom_50px {
    padding-bottom: 50px;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding-bottom: 20px; } }

/*Image Overlay Hover Effekt*/
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226, 0, 0, 0.7);
    display: flex;
    &:hover {
        opacity: 1; }
    .hover-cross {
        width: 80px!important;
        height: 80px!important;
        margin: auto; } }

img {
    display: block;
    max-width: 100%; }

.clear {
    clear: both;
    height: 0; }

.wrapper,
.block {
    width: 100%;
    max-width: 1720px;
    margin: 0 auto;
    padding: 0 2 * $base-width;
    position: relative;

    @media only screen and (min-width: #{map-get($breakpoints, xs)}) {
        padding: 0 3 * $base-width; } }

main {
    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        padding-top: 146px; }

    ul {
        list-style: none;
        margin-left: 0;
        padding-bottom: 3 * $base-width;

        li {
            position: relative;
            padding-left: 3 * $base-width;
            font-weight: 300;
            margin-bottom: $base-width;

            &:before {
                content: "+";
                font-weight: 600;
                font-size: 3rem;
                position: absolute;
                left: 0;
                top: -1px; } } } }

.wp-block-group {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 2 * $base-width 2 * $base-width;
    position: relative;

    @media only screen and (min-width: #{map-get($breakpoints, xs)}) {
        padding: 0 3 * $base-width 3 * $base-width; }

    &.center {
        text-align: center;
        margin-bottom: 6 * $base-width; }

    .element {
        padding-left: 0;
        padding-right: 0; }

    .wp-block-group__inner-container {
        margin: 0;

        @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
            margin: 0 -3 * $base-width; } } }

.element-image {
    padding: 0 0 6 * $base-width;

    &.center {
        img {
            margin: 0 auto; }

        span {
            text-align: center; } }

    span {
        font-style: italic;
        font-size: 1.4rem;
        color: rgba($primary, .8);
        line-height: 165%;
        display: block;
        padding-top: 2 * $base-width; } }

.element-hr {
    span {
        height: 1px;
        width: 100%;
        background: rgba($primary, .3);
        margin-bottom: 6 * $base-width;
        display: block; } }

.element-spacer {
    height: 6 * $base-width; }

.wow {
    visibility: hidden; }
