.galerie-box {
    height: 105px;
    border: 1px solid white;
    padding: 30px; }

.galerie-outerbox {
    justify-content: center;
    opacity: 0.80;
    a {
        width: 100%;
        display: contents; } }

.galerie-box {
    img {
        width: 100%;
        height: 100%;
        object-fit: contain; } }
