.element-accordion {
    .accordion-head {
        padding: 3 * $base-width;
        background: $success;
        color: $dark;
        margin-bottom: 3 * $base-width;
        cursor: pointer;
        transition: $transition;
        font-family: $font-text;
        font-size: 2rem;
        position: relative;

        @media only screen and (min-width: #{map-get($breakpoints, l)}) {
            font-size: 2.4rem; }

        i {
            position: absolute;
            right: 3 * $base-width;
            top: 26px;
            background: $dark;
            color: $success;
            border-radius: 50%;
            padding: 1px 0 0 1px;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
            transition: $transition; }

        &:hover {
            background: $warning;

            i {
                color: $orange; } }

        &.open {
            background: $orange;

            i {
                transform: rotateZ(180deg);
                padding: 0 0 1px;
                color: $orange; } } }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: $transition;

        &.open {
            max-height: 9999px; }

        .accordion-box {
            padding: 0 1.5 * $base-width 0;

            @media only screen and (min-width: #{map-get($breakpoints, l)}) {
                padding: 0 3 * $base-width 3 * $base-width; }

            .headline {
                display: block;
                font-family: $font-text;
                font-size: 2rem;
                letter-spacing: normal;
                padding: 0 0 4 * $base-width;
                font-weight: normal;

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                    font-size: 3rem; } }

            img {
                padding: 0 0 4 * $base-width; }

            .contacts {
                margin-top: -3 * $base-width;

                .contact {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid rgba($primary, .3);
                    padding-top: 6 * $base-width;
                    margin: 6 * $base-width 0 3 * $base-width;

                    @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                        flex-direction: row; }

                    .img {
                        width: 400px;
                        height: 300px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-bottom: 4 * $base-width;

                        @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                            margin-right: 6 * $base-width;
                            margin-bottom: 0; } }

                    .vita {
                        @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                            align-self: center; }

                        .name {
                            font-family: $font-text;
                            font-size: 2.6rem;
                            padding-bottom: 2 * $base-width; }

                        .position {
                            font-weight: bold; }

                        span {
                            display: inline-block;
                            width: 80px;
                            font-weight: bold; } } } } } } }
