#element-hero {
    position: relative;
    height: 320px;
    background-repeat: no-repeat;
    margin-bottom: 5 * $base-width;
    background-size: cover;
    background-position: center center;

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        margin-top: 0; }

    @media only screen and (min-width: #{map-get($breakpoints, l)}) {
        height: 400px;
        background-size: cover;
        background-position: center center;
        margin-bottom: 10 * $base-width; }

    &.text {
        height: auto;

        .img {
            width: 55vw; } }

    .img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-size: cover;
        background-position: center;
        width: 100%; }

    .intro-outer {
        display: flex;
        justify-content: flex-end;

        .intro-holder {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            @media only screen and (min-width: #{map-get($breakpoints, l)}) {
                max-width: 45vw;
                font-size: 2.2rem;
                margin-top: 0; }

            .intro {
                padding: 4 * $base-width 4 * $base-width $base-width;
                background: $success;
                position: relative;
                z-index: 200;
                color: $dark;

                @media only screen and (min-width: #{map-get($breakpoints, l)}) {
                    padding: 14 * $base-width 14 * $base-width 11 * $base-width; }

                h1 {
                    color: $dark;

                    &:after {
                        background: $dark; } }

                .subheadline {
                    font-size: 4rem;
                    font-weight: 300;
                    margin-top: -2 * $base-width;
                    margin-bottom: 4 * $base-width;
                    text-transform: uppercase;

                    em {
                        font-style: normal;
                        font-weight: 700; } } } } } }
