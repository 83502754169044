form {
	margin-bottom: 6 * $base-width;

	ul.contactform {
		list-style: none;
		margin: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			padding-left: 0;

			&:before {
				content: ""; } } }


	.form-head {
		font-size: 3rem;
		margin-bottom: 3 * $base-width;
		color: $success; }

	label {
		&.file {
			width: 100%;
			color: $primary;
			padding: 2 * $base-width;
			border: 1px solid rgba($primary, .5);
			background: transparent;
			cursor: pointer;
			display: block;

			span {
				margin: 0; }

			.icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 20px;
				width: 20px;
				height: 20px;

				use {
					width: 20px;
					height: 20px;
					fill: rgba($primary, .5); } } } }

	input {
		width: 100%;
		border: 1px solid rgba($primary, .5);
		padding: 2 * $base-width;
		font-family: $font-text;
		font-size: 2rem;

		&[type="checkbox"] {
			width: 20px; }

		&[type="file"] {
			width: 1px;
			height: 1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: 0; } }

	select {
		width: 100%;
		border: 1px solid rgba($primary, .5);
		padding: 2 * $base-width;
		font-family: $font-text;
		font-size: 2rem;

		option {
			font-family: Arial; } } // webfont not loading in FF - wtf

	textarea {
		width: 100%;
		border: 1px solid rgba($primary, .5);
		padding: 2 * $base-width;
		font-family: $font-text;
		font-size: 2rem;
		resize: none;
		height: 200px; }

	button {
		padding: 2 * $base-width;
		font-family: $font-text;
		font-size: 2rem;
		background: $success;
		color: $dark;
		border: none;
		cursor: pointer;
		transition: $transition;
		margin-top: 2 * $base-width;

		&:hover {
			background: $primary; }

		&[disabled=disabled] {
			opacity: .5;
			cursor: not-allowed;

			&:hover {
				background: $success; } } } }

.productformular {
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto; }
