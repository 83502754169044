
.leistungsbox {
	height: 400px;
	margin-bottom: 70px;
	@media (max-width: #{map-get($grid-breakpoints, lg)}) {
		height: 300px;
		margin-bottom: 25px; }
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none; } }
	&:hover {
		.title-container {
			margin-left: 0%;
			transition: 0.5s;
			margin-left: 8%; } } }

.leistung-bild {
	border: 3px solid transparent;
	transition: 0.5s ease-out;
	width: 100%;
	height: 100%;
	object-fit: cover; }

.title-container {
	width: 80%;
	margin-left: 6%;
	margin-top: -15%;
	margin-bottom: 15%;
	background-color: $primary;
	position: relative;
	transition: 0.5s;
	min-height: 110px;
	h2 {
		text-align: left;
		font-family: "Open Sans Regular";
		padding: 25px;
		color: white!important;
		font-size: 14px!important;
		font-weight: normal;
		line-height: 1.6;
		text-transform: capitalize;

		&:after {
			display: none; } } }

.block--leistungsboxen-drei-spalten {
	margin-bottom: 50px; }
