
.mitarbeiter-bg {
    //background-color: $light
    background-image: url('/wp-content/uploads/concrete_wall_2.jpg');
    padding: 60px 0;

    .mitarbeiter-icon {
        display: inline-block;
        width: 17px;
        height: auto;
        margin: 0 5px -3px 0; }

    .team-profil-container {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            flex-direction: column; }

        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            flex-direction: row; } }

    .team-profil-container-row {
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            justify-content: center; } }

    .team-image {
        max-width: 100%;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            max-height: 450px;
            max-width: 350px; }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%; } }




    .team-contact-info {
        text-align: left;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            text-align: center; }
        a,p {
            font-size: 18px!important;
            text-decoration: none;
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                font-size: 16px!important; }
            @media (max-width: #{map-get($grid-breakpoints, md)}) {
                font-size: 14px!important; } }

        a {
            &:hover {
                text-decoration: underline;
                color: $primary;
                transition: 0.5s; } } } }
