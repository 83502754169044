.fixed-top {
    background-color: white;
    height: 105px; }

.nav-shadow {
    box-shadow: 0px 0px 10px 1px rgba(51, 51, 51, 0.22);
    clip-path: inset(0px 0px -2000px 1px);
    width: 102%; }

.open-nav {
    transition: 0.5s;
    &::after {
        content: '';
        position: fixed;
        background-color: rgba(0,0 ,0 ,0.5);
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.langSelectShow {
    opacity: 1;
    transition: 0.5s;
    pointer-events: all; }

.langSelectDisappear {
    opacity: 0;
    transition: 0.5s;
    pointer-events: none; }

header {
    max-width: 1920px;
    margin: auto; }

.navbar-container {
    z-index: 999999;
    max-width: 1920px;
    margin: auto; }


.nav-lang-selector-container {
    border: none;
    position: absolute;
    height: 7.2rem;
    border-color: transparent;
    outline: 0;
    z-index: 1001;
    text-align: right;
    width: 80px;
    right: 100px;
    margin-right: 50px;
    margin-top: 50px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        margin-right: 25px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        right: 30px; }

    #nav-lang-selector {
        position: absolute;
        display: flex;
        right: 15px;
        font-size: 21px;
        color: #000;
        font-family: "Open Sans Light";
        background-color: white;
        border: none;
        text-transform: uppercase;
        select {
            border: none;
            &:focus-visible {
                border: none!important;
                outline: none!important; }
            &:hover {
                cursor: pointer;
                color: $primary; } }

        .current-lang {
            font-weight: bold; }
        li {
            list-style: none;
            margin-right: 10px;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none; } } } }

    #nav-lang-selector-list {
        display: none;
        margin-top: 40px;
        background-color: white;
        width: 80px;
        li {
            a {
                display: flex;
                justify-content: center;
                align-items: baseline;
                text-decoration: none;
                font-weight: 100;
                font-size: 20px; } } }

    .active {
        color: #000!important; } }

#nav-button-text {
    position: absolute;
    right: 40px;
    bottom: 25px;
    font-size: 21px;
    color: #000;
    font-family: $font-nav;
    padding-right: 20px;
    &:hover {
        color: $primary; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        display: none; } }

#nav-logo {
    width: 400px;
    height: auto;

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        width: 200px;
        margin-top: -5px; }

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        width: 160px; } }

.navbar {
    position: relative;
    padding: 1em 0; }

.navbar-nav {
    font-family: $font-nav; }

#menu-main-menu,
#menu-main-menu-en {
    background: linear-gradient(0deg, rgba(0,69,65,1) 10%, rgba(0,134,126,1) 70%);
    width: 100%;
    pointer-events: none; }

#bs4navbar {
    position: absolute;
    right: 0;
    top: 105px;
    right: -650px;
    z-index: 1050;
    opacity: 0;
    transition: 0.5s;
    width: 500px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        overflow-y: auto;
        height: 100vh;
        max-height: calc(100vh - 100px); }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        width: 340px; }

    ul {
        li {
            background-color: transparent;
            transition: 0.5s;
            &:nth-child(1) {
                margin-top: 40px; }
            &:last-child {
                margin-bottom: 40px; }

            &:hover {
                background-color: #161615;
                transition: 0.5s; } } }

    .show {
        background-color: #161615; } }

.show {
    .nav-item {
        a {
            &:focus,
            &:active, {
                background-color: transparent!important; }
            &:focus {
                color: white!important; } } }

    #menu-main-menu,
    #menu-main-menu-en {
        pointer-events: all!important; }

    .dropdown-menu {
        li {
            &:nth-child(1) {
                margin-top: 10px!important;
                padding-top: 10px!important; }
            &:last-child {
                margin-bottom: 10px!important;
                padding-bottom: 10px!important;
                a {
                    margin-bottom: 10px!important; } } } } }

.navbar > .show {
    right: -121px!important;
    transition: 0.5s!important;
    opacity: 1!important;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        right: -100px!important;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            right: -80px!important; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            right: -40px!important; }
        @media (max-width: #{map-get($grid-breakpoints, xs)}) {
            right: -15px!important; } } }

.nav-link {
    font-size: 20px;
    text-decoration: none;
    color: $nav-link-color!important;
    padding: 15px 15px 18px 50px!important;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 16px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 14px; }

    &:hover {
        color: $nav-link-hover-color!important; } }
#menu-main-menu,
#menu-main-menu-en, {
    .active {
        .nav-link {
            background-color: #161615; } }
    .active {
        color: $nav-link-hover-color!important;
        background-color: #161615; } }

.dropdown-menu {
    min-width: 300px;
    padding: 0px 15px 15px 30px;
    background-color: #161615!important;
    margin: 0;
    border: none;
    border-radius: none; }

.dropdown-menu > .nav-link {
    font-size: 16px;
    padding: 5px 0 5px 15px;
    font-family: $font-nav; }

.dropdown-toggle::after {
    margin-left: 10px; }

.dropdown-item {
    font-size: 16px;
    text-decoration: none;
    color: white;
    padding: 0 5%;
    margin-bottom: 30px;
    transition: 0.5s;
    &:hover {
        color: $primary;
        transition: 0.5s;
        background-color: #161615;
        &:focus {
            color: $primary;
            transition: 0.5s;
            background-color: #161615; }
        &:active {
            color: $primary;
            transition: 0.5s;
            background-color: #161615; } }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 14px; } }

.current-menu-item {
    a {
        color: $primary; } }

.dropdown-item.active {
    background-color: #161615;
    color: $primary; }

/*Navigation 3 Level */
ul.dropdown-menu li {
    > ul.dropdown-menu {
        left: 100%;
        top: -26px; } }

ul.dropdown-menu li:hover {
    > ul.dropdown-menu {
        display: block; } }

ul.dropdown-menu li:focus {
    > ul.dropdown-menu {
        display: block; } }

.dropdown-menu > .nav-link {
    margin-left: 0;
    padding: 15px 0 15px 15px;
    border-bottom: 2px solid white;
    @media (max-width: 991px) {
        font-size: 1.25rem;
        text-align: center;
        padding: 0.5%; }
    &::before {
        margin-left: -5px; } }

.navigation-icon {
    display: inline-block;
    width: 22px;
    height: auto;
    margin: 0 20px -3px 0; }

/*Dropdown Pfeile*/
.dropdown-toggle {
    &::after {
        content: url('/wp-content/uploads/icon_down-arrow_white.svg');
        border: none;
        width: 16px;
        height: auto;
        margin-left: 10px;
        position: relative;
        top: 3px; } }

.dropdown-menu > .dropdown-toggle {
    &::after {
        content: url('/wp-content/uploads/icon_down-arrow_white.svg'); } }

/*Navigation menu mobil*/
@media (max-width: 1200px) {

    .dropdown-menu > .dropdown-toggle {
        &::after {
            content: url('/wp-content/themes/kreka/assets/icons/caret-down.svg');
            border: none;
            width: 12px;
            height: auto;
            margin-left: 10px;
            position: relative;
            top: 8px;
            margin-top: -5px; } } }

@media (max-width: 980px) {

    .dropdown-menu > .nav-link {
        font-size: 12px; }

    .dropdown-menu > .dropdown-menu >.nav-link {
        font-size: 10px; }

    .navigation-icon {
        display: inline-block;
        width: 16px;
        height: auto;
        margin: 0 15px -3px 0; } }

.navbar-toggler-icon {
    width: 30px;
    height: 30px; }

.navbar-toggler {
    border: none;
    position: relative;
    height: 9*$base-width;
    padding: 3*$base-width $base-width;
    border-color: transparent;
    outline: 0;
    z-index: 1001;
    text-align: right;
    @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
        position: absolute;
        top: 20px;
        right: 30px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        top: 20px;
        right: 0px; }

    &:hover {
        .icon-bar {
            background-color: $primary; }
        #nav-button-text {
            color: $primary; } }

    .icon-bar {
        display: block;
        height: 2px;
        margin-bottom: 8px;
        background-color: #000;
        transition: all 0.2s;
        &.top-bar {
            width: 30px; }
        &.middle-bar {
            width: 30px; }
        &.bottom-bar {
            width: 30px;
            margin-bottom: 0px;
            margin-left: auto; } }

    @keyframes top-bar {
        0% {
            top: 2px;
            width: 30px;
            transform: rotate(0deg); }
        40% {
            top: 10px;
            width: 30px;
            transform: rotate(0deg); }
        60% {
            width: 30px;
            top: 10px;
            transform: rotate(0deg); }
        100% {
            width: 30px;
            top: 10px;
            transform: rotate(-45deg); } }
    @keyframes middle-bar {
        0% {
            top: 10px;
            width: 30px; }
        40% {
            top: 10px;
            width: 30px; }
        60% {
            width: 0px;
            top: 10px; }
        100% {
            width: 0px;
            top: 10px; } }
    @keyframes bottom-bar {
        0% {
            top: 10px;
            width: 30px;
            transform: rotate(0deg); }
        40% {
            top: 10px;
            width: 30px;
            transform: rotate(0deg); }
        60% {
            width: 30px;
            top: 10px;
            transform: rotate(0deg); }
        100% {
            width: 30px;
            top: 10px;
            transform: rotate(45deg); } }
    &.collapsed {
        span.icon-bar {
            width: 30px !important;
            &.top-bar {
                -webkit-animation: top-bar .4s linear forwards;
                -o-animation: top-bar .4s linear forwards;
                animation: top-bar .4s linear forwards;
                margin-bottom: -1px;
                background-color: #000;
                width: 30px; }
            &.middle-bar {
                -webkit-animation: middle-bar .4s linear forwards;
                -o-animation: middle-bar .4s linear forwards;
                animation: middle-bar .4s linear forwards;
                display: none; }
            &.bottom-bar {
                -webkit-animation: bottom-bar .4s linear forwards;
                -o-animation: bottom-bar .4s linear forwards;
                animation: bottom-bar .4s linear forwards;
                background-color: #000;
                width: 30px;
                margin-left: 0; } } } }
