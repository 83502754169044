.block--action-banner {
	background-color: $primary;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; }

.inner-block--action-banner h1,
.inner-block--action-banner h2,
.inner-block--action-banner h3,
.inner-block--action-banner h4 {
	text-align: center;
	color: white; }

.inner-block--action-banner {
	padding-top: 80px;
	padding-bottom: 80px;
	p {
		color: white;
		text-align: center; }

	a {
		margin-top: 30px; } }
