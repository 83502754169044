.block--text-zwei-spalten {
  .left-col {
    padding: 0 60px 0 30px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 0 30px 0 30px!important; }
    .btn {
      margin-top: 50px; } }

  .right-col {
    padding: 0 60px 0 30px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 0 30px 0 30px!important; }
    .btn {
      margin-top: 50px; } }

  a {
    &:hover {
      transform: rotate(0deg) !important;
      .button-link-icon {
        transform: rotate(0deg) !important;
        margin-left: 40px;
        margin-right: -20px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        transition: 0.5s; } }

    .button-link-icon {
      height: 18px;
      display: inline-flex;
      margin-left: 20px;
      filter: invert(26%) sepia(64%) saturate(2981%) hue-rotate(160deg) brightness(96%) contrast(102%);
      transition: 0.5s; } } }
