#product-single {
  padding-top: 30 * $base-width;
  color: $body-text-color;

  @media (max-width: #{map-get($breakpoints, lg)}) {
    padding-top: 18 * $base-width; }

  .product-intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .product-info {
      flex: 0 0 60%;

      @media (max-width: #{map-get($breakpoints, lg)}) {
        flex: 0 0 100%; }

      .h2 {
        text-transform: none; }

      .table-dscr {
        margin-top: 6 * $base-width;
        margin-bottom: 6 * $base-width;

        h2, h3 {
          margin-bottom: 2 * $base-width;
          font-size: 2.8rem;
          line-height: 115%;

          @media (max-width: #{map-get($breakpoints, md)}) {
            font-size: 2.1rem; } } }

      .table {
        margin-bottom: 8 * $base-width;

        @media (max-width: #{map-get($breakpoints, md)}) {
          margin-bottom: 4 * $base-width; }

        .row {
          display: flex;
          border-top: 2px solid $bg-grey;
          margin: 0;

          &:last-child {
            border-bottom: 2px solid $bg-grey; }

          .col {
            padding: 3 * $base-width 2 * $base-width;
            flex: 0 0 70%;

            @media (max-width: #{map-get($breakpoints, md)}) {
              flex: 0 0 60%;
              font-size: 1.6rem; }

            @media (max-width: #{map-get($breakpoints, sm)}) {
              padding: 2 * $base-width 1 * $base-width;
              hyphens: auto; }

            &:first-child {
              padding: 3 * $base-width $base-width;
              flex: 0 0 30%;
              border-right: 2px solid $bg-grey;
              font-weight: 800;
              text-transform: uppercase;

              @media (max-width: #{map-get($breakpoints, md)}) {
                flex: 0 0 40%;
                font-size: 1.6rem; }

              @media (max-width: #{map-get($breakpoints, sm)}) {
                hyphens: auto; } } } } }

      .table-3-rows {
        margin-bottom: 8 * $base-width;

        @media (max-width: #{map-get($breakpoints, md)}) {
          margin-bottom: 4 * $base-width; }

        .row {
          display: flex;
          border-top: 2px solid $bg-grey;
          margin: 0;

          &:first-child {
            font-weight: 800;
            background-color: #ededed; }


          &:last-child {
            border-bottom: 2px solid $bg-grey; }

          .col {
            padding: 3 * $base-width 2 * $base-width;
            flex: 0 0 33.33%;

            @media (max-width: #{map-get($breakpoints, md)}) {
              font-size: 1.6rem; }

            @media (max-width: #{map-get($breakpoints, sm)}) {
              padding: 2 * $base-width 1 * $base-width;
              hyphens: auto; }

            &:first-child, &:nth-child(2) {
              padding: 3 * $base-width $base-width;
              border-right: 2px solid $bg-grey;

              @media (max-width: #{map-get($breakpoints, md)}) {
                font-size: 1.6rem; }

              @media (max-width: #{map-get($breakpoints, sm)}) {
                hyphens: auto; } } } } } }



    .col-right {
      flex: 0 0 30%;

      @media (max-width: #{map-get($breakpoints, lg)}) {
        flex: 0 0 100%;
        margin-top: 4 * $base-width;
        margin-bottom: 4 * $base-width; }

      .product-image {

        picture {
          padding-top: 100%;
          position: relative;
          display: block;
          width: 100%;
          margin-bottom: 3 * $base-width;

          @media (max-width: #{map-get($breakpoints, lg)}) {
            width: 50%;
            margin: 0 auto;
            padding-top: 35%; }

          @media (max-width: #{map-get($breakpoints, sm)}) {
            width: 100%;
            padding-top: 56.25%;
            margin-bottom: 3 * $base-width; }

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center; } } }

      .logos {
        width: 100%;

        picture {
          padding-top: 90px;
          position: relative;
          display: block;
          width: 100%;
          margin-bottom: 3 * $base-width;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center; } } } } }


  .product-dscr {
    background-color: $bg-grey;
    padding-top: 10 * $base-width;
    padding-bottom: 10 * $base-width;

    @media (max-width: #{map-get($breakpoints, md)}) {
      padding-top: 6 * $base-width;
      padding-bottom: 6 * $base-width; } }

  .text-images {
    padding-top: 10 * $base-width;
    padding-bottom: 10 * $base-width;

    @media (max-width: #{map-get($breakpoints, md)}) {
      padding-top: 6 * $base-width;
      padding-bottom: 6 * $base-width; }

    + .text-images {
      padding-top: 0; }

    .text-images-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.teaser-wrapper {
        padding: 4 * $base-width;
        background-color: $bg-grey;
        border-radius: 10px;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;

        .text {
          margin-bottom: 0;
          padding-left: 10 * $base-width;

          @media (max-width: #{map-get($breakpoints, lg)}) {
            padding-left: 5 * $base-width;
            padding-right: 5 * $base-width; }

          @media (max-width: #{map-get($breakpoints, md)}) {
            margin-top: 2 * $base-width;
            padding-left: 0; } }

        .images {
          flex: 0 0 50%;
          width: 50%;

          picture {
            margin-bottom: 0;

            img {
              mix-blend-mode: multiply; } }

          &.size-25 {
            flex: 0 0 25%;
            width: 25%;

            @media (max-width: #{map-get($breakpoints,lg)}) {
              flex: 0 0 50%;
              width: 50%; }

            @media (max-width: #{map-get($breakpoints, md)}) {
              flex: 0 0 100%;
              width: 100%; } } } }

      .text {
        flex: 1 0 50%;
        width: 50%;
        padding-right: 10 * $base-width;

        @media (max-width: #{map-get($breakpoints, lg)}) {
          padding-right: 5 * $base-width; }

        @media (max-width: #{map-get($breakpoints, md)}) {
          flex: 1 0 100%;
          width: 100%;
          margin-bottom: 4 * $base-width;
          padding-right: 0; }

        .buttons {
          padding-top: 3 * $base-width;
          display: flex;
          flex-wrap: wrap;

          .btn {
            margin: 1 * $base-width; } } }

      .images {
        flex: 0 0 50%;
        width: 50%;

        @media (max-width: #{map-get($breakpoints, md)}) {
          flex: 0 0 100%;
          width: 100%; }

        picture {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          margin-bottom: 2 * $base-width;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain; } }

        .owl-nav {
          @media (max-width: #{map-get($breakpoints, sm)}) {
            display: flex;
            justify-content: center;
            margin-top: 2 * $base-width; }

          button {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              height: 15px;
              width: 15px;
              fill: $primary;
              transition: $transition;

              @media (max-width: #{map-get($breakpoints, lg)}) {
                height: 10px;
                width: 10px; } }

            &.owl-prev, &.owl-next {
              position: absolute;
              top: 45%;
              transform: translateY(-50%);
              height: 50px;
              width: 50px;
              background-color: white;
              border: 2px solid $primary;
              color: $primary;
              border-radius: 50%;
              padding: 0;
              transition: $transition;

              @media (max-width: #{map-get($breakpoints, lg)}) {
                height: 40px;
                width: 40px; }

              @media (max-width: #{map-get($breakpoints, sm)}) {
                position: relative;
                transform: none;
                margin: $base-width; }

              &:hover {
                background-color: $secondary;
                transition: $transition;

                svg {
                  fill: white;
                  transition: $transition; } } }

            &.owl-prev {
              left: -25px;

              @media (max-width: #{map-get($breakpoints, lg)}) {
                left: -20px; }

              @media (max-width: #{map-get($breakpoints, sm)}) {
                left: 0; } }

            &.owl-next {
              right: -25px;

              @media (max-width: #{map-get($breakpoints, lg)}) {
                right: -20px; }

              @media (max-width: #{map-get($breakpoints, sm)}) {
                right: 0; } } } }

        .product-image-caption {
          font-size: 1.4rem;
          line-height: 125%;
          margin-top: 2 * $base-width;
          color: $body-link-hover-color;
          text-align: center; } } } }


  .download {
    background-color: $bg-grey;
    padding-top: 10 * $base-width;
    padding-bottom: 10 * $base-width;

    @media (max-width: #{map-get($breakpoints, md)}) {
      padding-top: 6 * $base-width;
      padding-bottom: 6 * $base-width; }

    .wrapper {
      max-width: $small;

      h2 {
        text-align: center;
        margin-bottom: 4 * $base-width; }

      .btn-download {
        padding: 3 * $base-width 4 * $base-width;
        background-color: white;
        border: 2px solid $secondary;
        color: $secondary;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 1.8rem;
        line-height: 125%;
        text-decoration: none;
        width: 100%;
        text-align: left;
        justify-content: space-between;
        margin-bottom: 3 * $base-width;

        @media (max-width: #{map-get($breakpoints, sm)}) {
          padding: 2 * $base-width 2 * $base-width; }

        svg {
          height: 22px;
          flex: 0 0 22px;
          width: 22px;
          fill: $primary;
          transition: $transition; }

        &:hover {
          background-color: $primary;
          color: white;

          svg {
            fill: white;
            transition: $transition; } } } } }


  .request-block {
    padding-top: 10 * $base-width;
    padding-bottom: 10 * $base-width;

    @media (max-width: #{map-get($breakpoints, md)}) {
      padding-top: 6 * $base-width;
      padding-bottom: 6 * $base-width; } } }
