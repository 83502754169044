.block-products {
    padding-top: 20 * $base-width;
    padding-bottom: 20 * $base-width;

    .headline-wrapper {
        text-align: center;
        margin-bottom: 6 * $base-width;

        @media (max-width: #{map-get($breakpoints, sm)}) {
            margin-bottom: 4 * $base-width; } }

    .products {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4 * $base-width;
        text-decoration: none;

        @media (max-width: #{map-get($breakpoints, lg)}) {
            margin: 0 -2 * $base-width; }

        .image-item {
            flex: 0 0 33.333%;
            width: 33.333%;
            padding: 4 * $base-width;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            align-content: end;
            text-decoration: none;

            @media (max-width: #{map-get($breakpoints, lg)}) {
                padding: 2 * $base-width; }

            &:hover {
                .inner {
                    &:before {
                        opacity: 1;
                        transition: $transition; }

                    &:after {
                        opacity: 1;
                        transition: $transition; } } }


            @media (max-width: #{map-get($breakpoints, lg)}) {
                flex: 0 0 50%;
                width: 50%; }

            @media (max-width: #{map-get($breakpoints, sm)}) {
                flex: 0 0 100%;
                width: 100%; }

            .title {
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 2 * $base-width;
                font-size: 2rem;
                color: $primary;
                text-align: center;
                flex: 0 0 100%;
                width: 100%;
                text-decoration: none;

                @media (max-width: #{map-get($breakpoints, xxxl)}) {
                    font-size: 1.8rem;
                    line-height: 1.2; }

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    font-size: 1.6rem;
                    line-height: 1.2; } }

            .inner {
                padding: 3 * $base-width;
                flex: 0 0 100%;
                width: 100%;
                position: relative;
                background-color: #f8f8f8;
                border-radius: 10px;

                &:before {
                    content: "";
                    background-color: rgba($primary, 0.77);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    transition: $transition;
                    z-index: 1;
                    opacity: 0; }

                &:after {
                    content: "";
                    background-image: url('/wp-content/themes/bergauf/assets/img/icons/plus.svg');
                    background-repeat: no-repeat;
                    background-size: 70px 70px;
                    background-position: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    transition: $transition;
                    z-index: 1;
                    opacity: 0; }


                picture {
                    padding-top: 75%;
                    position: relative;
                    display: block;
                    width: 100%;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        mix-blend-mode: multiply; } } } } } }


.block-text-columns.bg-weiss + .block-products {
    padding-top: 4 * $base-width; }
