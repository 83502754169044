
#footer_wrap {
    background-color: $primary;

    #footer h6 {
        color: $footer-header-color;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 25px;
        text-shadow: 0 0 black; }

    #footer p {
        color: $footer-text-color;
        font-size: 17px;
        display: inline-block;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 14px; } }

    #footer a {
        text-decoration: none;
        color: $footer-link-color;
        font-size: 14px;
        transition: 0.5s;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 14px; }
        &:hover {
            color: $footer-link-hover-color;
            text-decoration: underline;
            transition: 0.5s; } }

    #footer {
        ul {
            list-style: none;
            li, a {
                color: $footer-text-color;
                font-size: 12px;
                display: inline-block;
                margin-right: 20px;
                @media (max-width: #{map-get($grid-breakpoints, md)}) {
                    display: block;
                    margin: 5px; } } } }

    .footer-divider {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.33) 30%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 15px 1px;
        background-repeat: repeat-x;
        height: 1px;
        margin-bottom: 15px; }

    .footer-logo {
        margin-top: 60px;
        margin-bottom: 30px; }

    .footer-kontakt {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            flex-direction: column; }

        .footer-kontakt-container {
            display: flex;
            justify-content: center;

            .footer-kontakt-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: auto;
                    height: 20px;
                    margin-right: 10px;
                    @media (max-width: #{map-get($grid-breakpoints, md)}) {
                        margin-right: 0px;
                        margin: 5px; } } }

            .footer-kontakt-info {
                font-family: $font-text;
                color: white;
                font-size: 17px;
                margin-right: 50px;
                display: flex;
                align-items: center;
                @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                    font-size: 14px; }
                @media (max-width: #{map-get($grid-breakpoints, md)}) {
                    margin-right: 0px;
                    margin: 10px; } } } }

    .footer-menu {
        margin-bottom: 100px; }

    #menu-footer-menu {
        .current-menu-item {
            a {
                text-decoration: underline!important; } } } }
