.block--testimonials {

  .testimonial-slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 30px;

    .testimonial-slider-item_image {
      width: 180px;
      height: 180px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 100%;
        objec-fit: cover;
        border-radius: 50%; } }

    .testimonial-slider-item_name {
      margin-bottom: 15px;
      text-align: center;
      color: $body-text-color;
      font-size: 16px;
      .position {
        font-size: 12px;
        margin-bottom: 30px; } }

    .testimonial-slider-item_comment {
      .quote-symbol {
        img {
          width: 20px;
          height: 20px;
          display: inline; } }
      text-align: center;
      color: $body-text-color; } }

  .owl-dots {
    display: flex!important;
    width: 100%;
    height: 20px;
    justify-content: center;
    margin-top: 30px;
    .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      border: 2px solid $primary;
      background-color: transparent; }
    .active {
      background-color: $primary; } } }
