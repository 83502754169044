// Globals
@import './globals/vars';
@import './globals/fonts';

// Third-Party
@import '~bootstrap/scss/bootstrap';
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

// Base
@import './globals/base';

// Blocks
@import './blocks/mitarbeiter';
@import './blocks/actionBanner';
@import './blocks/akkordeon';
@import './blocks/galerie';
@import './blocks/listen';
@import './blocks/tabellen';
@import './blocks/text';
@import './blocks/testimonials';
@import './blocks/textMitNummer';
@import './blocks/zeitLeiste';
@import './blocks/leistungen';
@import './blocks/formular';
@import './blocks/textMitMedien';
@import './blocks/leistungsIcons';
@import './blocks/branchen';
@import './blocks/modal';
@import './blocks/zweiSpaltenText';
@import './blocks/videos';
@import './blocks/products';

// Layout
@import './layout/buttons';
@import './layout/typo';
@import './layout/header';
@import './layout/navigation';
@import './layout/footer';
@import './layout/breadCrumb';
@import './layout/gutenberg-grid';
@import './layout/borlabs';

// Partials
@import './partials/list-pages';
@import './partials/wysiwyg';
@import './partials/link';
@import './partials/gallery';
@import './partials/accordion';
@import './partials/divider';
@import './partials/hero';
@import './partials/form';
@import './partials/animate';

@import './singles/produkte';
