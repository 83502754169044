.element-link {
    padding-bottom: 3 * $base-width;
    display: inline-block;

    &.center {
        text-align: center;
        width: 100%;
        margin-left: 3 * $base-width;
        margin-right: 3 * $base-width; }

    i.fa {
        color: $success;
        padding-right: $base-width; }

    a {
        &.button {
            padding: 3 * $base-width 5 * $base-width;
            display: inline-block;
            background: $success;
            color: $dark;
            font-family: $font-text;
            font-size: 2rem;
            transition: $transition;
            text-decoration: none;

            @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                padding: 3 * $base-width 10 * $base-width;
                font-size: 2.5rem; }

            &:hover {
                background: $warning; } } } }


