.breadcrumb {
    background-color: white;
    .separator {
        margin: 0 25px; }
    .item-current {
        color: $body-link-color;
        strong {
            font-weight: normal; } }
    .item-parent,
    .item-home {
        color: $body-text-color; }
    a {
        color: $body-text-color;
        &:hover {
            text-decoration: underline; } } }


@media (max-width: 1200px) {
    .breadcrumb-container {
        display: none; } }

.breadcrumb-icon {
    display: inline-block;
    width: 9px;
    height: auto;
    margin: 0px 0px -4px 0; }
