
// .modal-backdrop
//   display: none
.mediaModal {
  z-index: 9999999; }

.modal-open {
  padding-left: 0px!important;
  padding-right: 0px!important; }

.mediaModal {
  padding-left: 10px!important;
  padding-right: 10px!important;
  .modal-content {
    background-color: transparent;
    //box-shadow: 0px 0px 30px black
    padding: 0px;
    border: none;

    .modal-header {
      border-bottom: unset;
      position: absolute;
      right: -20px;
      z-index: 9;
      top: -20px;
      #close-modal-button {
        width: 50px;
        height: 50px;
        background-color: $primary;
        border-radius: 50%;
        opacity: 1;
        margin-bottom: -40px;
        z-index: 9;
        color: #fff; }
      .close {
        font-size: 30px;
        margin: 0 0 -1rem auto; } }

    .modal {
      background-color: rgba(0, 0, 0, 0.75);
      .modal-dialog {
        .modal-content {
          margin: auto;

          .modal-body {

            @media (max-width: #{map-get($grid-breakpoints, sm)}) {
              iframe {
                width: 100%!important;
                height: auto!important; } } } } } } } }

@media (min-width: 600px) {
  .modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto; } }

.embed-responsive-16by9::before {
  padding-top: 0 !important; }

.embed-responsive.embed-responsive-16by9 {
  height: 550px !important;
  width: 100% !important;

  iframe {
    position: relative !important; } }

.brlbs-cmpnt-cb-play-button {
  display: none !important; }

.brlbs-cmpnt-cb-preset-c {
  height: 550px !important; }
