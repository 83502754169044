.block--branchen {

  h2 {
    margin-top: 90px;
    margin-bottom: 90px;
    text-align: center; }

  .row {
    justify-content: center; }

  .branchen-container {
    justify-content: space-around;
    width: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    position: relative;
    border-right: white solid 5px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      height: 350px;
      background-position: center; }

    &:last-child() {
      border-right: white solid 0px; }
    @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
      border-right: white solid 0px;
      width: 33.33333%;
      &:not(:nth-child(odd)) {
        border-right: white solid 5px; }
      &:nth-child(1) {
        border-right: white solid 5px; } }

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      border-right: white solid 0px;
      width: 50%;
      border-bottom: white solid 5px;
      &:not(:nth-child(even)) {
        border-right: white solid 5px; }
      &:nth-child(even) {
        border-right: white solid 0px; } }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      border-right: white solid 0px;
      width: 100%;
      &:not(:nth-child(even)),
      &:not(:nth-child(odd)),
      &:not(:nth-child(1)) {
        border-right: white solid 0px; } }

    .branchen-titel-container {
      width: 80%;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      h3 {
        hyphens: auto;
        text-align: center;
        color: white; } }

    .overlay-branchen-1 {
      position: absolute;
      opacity: 1;
      transition: 0.5s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 138, 129, 0.77); }

    .overlay-branchen-2 {
      position: absolute;
      opacity: 1;
      transition: 0.5s;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 1) 100%); } }

  .branchen-container {
    &:hover {
      .overlay-branchen-1 {
        transition: 0.5s;
        opacity: 0; } } } }
