/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans Light';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/open-sans/open-sans-v27-latin-300.woff2') format('woff2'),
    url('../../fonts/open-sans/open-sans-v27-latin-300.woff') format('woff') {} }

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans Regular';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/open-sans/open-sans-v27-latin-regular.woff2') format('woff2'),
    url('../../fonts/open-sans/open-sans-v27-latin-regular.woff') format('woff') {} }

/* changa-one-regular - latin */
@font-face {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/changa-one/changa-one-v13-latin-regular.woff2') format('woff2'),
    url('../../fonts/changa-one/changa-one-v13-latin-regular.woff') format('woff') {} }
